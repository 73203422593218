const PRIVACY = "https://img.firefix.cn/downloaderx/privacy.html";
const AGREEMENT = "https://img.firefix.cn/downloaderx/agreement.html";
const ANDROID_URL = "https://img.firefix.cn/downloaderx/apk/tobesaver.apk";
const WEB_URL = "https://unwatermarker.cn/#/office";
const WINDOWS_URL = "https://img.firefix.cn/downloaderx/windows/tobesaver.exe";
const BEIAN_URL = "https://beian.miit.gov.cn/#/Integrated/index";
export default {
    PRIVACY,
    AGREEMENT,
    ANDROID_URL,
    WEB_URL,
    BEIAN_URL,
    WINDOWS_URL
};
