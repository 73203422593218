<template>
  <div class="mobile-toast" :class="{ active: isActive }">
    <span>{{ message }}</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
      message: ""
    };
  },
  methods: {
    show(message) {
      this.message = message;
      this.isActive = true;

      setTimeout(() => {
        this.hide();
      }, 3000);
    },
    hide() {
      this.isActive = false;
    }
  }
};
</script>

<style scoped>
.mobile-toast {
  position: fixed;
  bottom: 20rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #FFFFFF;
  color: #000;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.mobile-toast.active {
  opacity: 1;
}
</style>
