<template>
  <div
      style="height: 4rem;background-color: #202123;display: flex;align-items: center;justify-content: space-between;padding-right: 20px;padding-left: 20px;position: fixed;left: 0;right: 0;">
    <div style="display: flex;align-items: center;justify-content: center">
      <img src="@/assets/logo.png" style="width: 40px;height: 40px;border-radius: 50%">
      <p style="color: white;margin-left: 20px;font-size: 1.3rem;font-weight: bold">TobeSaver</p>
    </div>
    <div  @click="download"
        style="background-color: #8983F7;height: 2.4rem;border-radius: 4px;border-width: 0;color: white;font-size: 1rem;padding-left: 14px;padding-right: 14px;display: flex;align-items: center;justify-content: center">
      <a>Download</a>
      <img src="@/assets/arrow_down.png" style="width: 0.8rem;height: 0.9rem;margin-left: 0.5rem">
    </div>

  </div>
</template>

<script>
export default {
  methods: {
    download() {
      var a = document.createElement('a')
      a.download = name || 'apk'
      a.href = this.$config.ANDROID_URL;
      a.click();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
