<template>
  <Head/>
  <Body/>
</template>

<script>
import Head from './components/HomeHead.vue'
import Body from './components/HomeBody.vue'

export default {
  components: {
    Head,
    Body
  }
}
</script>

<style scoped>

</style>
