<template>
  <div style="padding-left: 1rem;padding-right:1rem;padding-top:7rem; overflow: auto; touch-action: auto; /* 或 touch-action: pan-y; */
  overflow-scrolling: touch;">
    <starry-sky style="width: 100%; height: 100%; z-index: -990; position: absolute"></starry-sky>
    <div style="display: flex;align-items: center;justify-content: center">
      <img id="img-store" src="@/assets/apk-zh.png" @click="openApkZh()">
      <!--      <img id="img-store" src="@/assets/app-store.png" @click="openAppStore()">-->
      <img id="img-store" src="@/assets/web.jpg" @click="openWeb()">
      <img v-if="!isMobile" id="img-store" src="@/assets/windows.jpg" @click="openWindows()">

    </div>
    <div style="display: flex;flex-direction: row;justify-content: flex-start;" @click="startVideoPlayback">
      <video ref="videoPlayer" v-if="!isMobile" style="width: 320px;" src="@/assets/video.mp4" autoplay loop muted
      ></video>

      <div
          style="display: flex;flex-direction: column;align-items: flex-start;justify-content: center;">
        <p id="type-title">·无人直播功能</p>
        <span id="des">无需真人出镜,点击开始直播后获取推流地址剩下的事情交给我们来推流;类似挂机游戏不需要电脑也不需要手机,支持24小时不断播放电影、直播慢音乐、挂载小黄车直播带货;效果:自动引流涨粉，曝光您的作品、观众打赏赚钱收益,参与平台活动,带货获取佣金。</span>
        <p id="type-title">·短视频、图片去水印功能</p>
        <span id="des">一键复制视频链接粘贴到输入框,点击解析视频按钮即可下载各大平台无水印短视频;支持平台:抖音、快手、TikTok、西瓜视频、YouTobe、bilibili、小红书、微博、等180个平台;效果:短视频搬运养号,赚取平台佣金。<br></span>
      </div>
      <video v-if="isMobile" ref="videoPlayer" style="width: 8rem;" src="@/assets/video.mp4" autoplay loop muted
      ></video>

    </div>
    <!--    <div style="display: flex;align-items: center;justify-content: center">-->
    <!--      <a style="color: white;margin-top: 1rem;font-size: 1.2rem"> 受到9300万用户的喜爱。</a>-->

    <!--    </div>-->
    <div style="position: fixed;bottom: 2rem;left: 0;right: 0">
      <div style="display: flex;flex-direction: row;align-items: center;justify-content: center">
        <a id="bottom" :href="$config.AGREEMENT" target="_blank"> 用户协议</a>
        <span id="line"></span>
        <a id="bottom" :href="$config.PRIVACY" target="_blank"> 隐私政策</a>
        <span id="line"></span>
        <p id="bottom" target="_blank">联系我们:xhxdeveloper@163.com</p>
      </div>
      <p id="bottom">Copyright @2018-2023 武汉小浣熊网络科技有限公司</p>
      <div style="display: flex;align-items: center;justify-content: center"><a id="bottom" target="_blank"
                                                                                :href="$config.BEIAN_URL">鄂ICP备2023007798号-1</a>
      </div>
    </div>
    <toast ref="toast"></toast>

  </div>

</template>

<script>
import toast from "./XToast.vue";
import StarrySky from "@/pages/components/StarrySky";

export default {
  data() {
    return {
      isMobile: false,
    }
  },
  components: {
    StarrySky,
    toast,
  },
  mounted() {
    this.isMobile = window.innerWidth <= 768;
    window.addEventListener('resize', this.handleResize);
    // document.addEventListener('click', this.startVideoPlayback);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    // document.removeEventListener('click', this.startVideoPlayback);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    },
    startVideoPlayback() {
      this.$refs.videoPlayer.muted = false;
      if (!this.$refs.videoPlayer.paused) return;
      this.$refs.videoPlayer.play();
    },
    openApkZh() {
      var a = document.createElement('a')
      a.download = name || 'apk'
      a.href = this.$config.ANDROID_URL;
      a.click();
    },
    openWeb() {
      window.open(this.$config.WEB_URL, '_blank');
    },
    openWindows(){
      window.open(this.$config.WINDOWS_URL, '_blank');
      // this.$refs.toast.show("开发中，请耐心等待!");
    }
  }
}
</script>

<style scoped>
#img-store {
  height: 40px;
  margin: 0.4rem;
  border-radius: 6px;
}

#type-title {
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
}

#des {
  color: ghostwhite;
  font-size: 1.0rem;
  text-align: left;
}

#line {
  width: 2px;
  height: 10px;
  background-color: gray;
  margin-left: 15px;
  margin-right: 15px
}

#bottom {
  color: gray;
  font-size: 0.7rem;
  text-align: center;
}
</style>
